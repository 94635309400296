<template>
  <div>
    <b-form @submit.prevent="updateSub">
      <b-row>
        <b-col sm="12" lg="10">
          <b-row>
            <b-col cols="12" class="pb-3">
              <label>
                Voornaam
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.firstname"
                disabled
                required
              ></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                Achternaam
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.lastname"
                disabled
                required
              ></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                E-mailadres
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.email"
                disabled
                required
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import { modifySub, getSubs } from '@/services/AuthorizationService'

import { BForm, BRow, BCol, BFormInput } from 'bootstrap-vue'

export default {
  name: 'AccountUser',
  components: {
    BForm,
    BRow,
    BCol,

    BFormInput
  },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        username: '',
        firstname: '',
        lastname: '',
        tel: '',
        email: '',
        MobilePhone: '',
        password: '',
        passwordConfirm: ''
      },
      subUser: null,
      processing: false
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    }
  },
  created: async function() {
    await getSubs()
    this.form.username = this.user.UserName
    this.form.firstname = this.user.FirstName
    this.form.lastname = this.user.LastName
    this.form.tel = this.user.Tel
    this.form.MobilePhone = this.user.MobilePhone
    this.form.email = this.user.EmailAddress
    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$store.getters.account.Email
    })

    this.form = {
      email: this.subUser.Email || '',
      street: this.subUser.CUST_Street,
      city: this.subUser.CUST_City,
      houseNumber: this.subUser.CUST_HouseNumber,
      postcode: this.subUser.CUST_ZipCode,
      tel: this.subUser.Tel,
      firstname: this.subUser.FirstName,
      lastname: this.subUser.LastName,
      MobilePhone: this.subUser.MobilePhone
    }
  },
  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    },
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      const result = await modifySub({
        email: this.form.email || '',
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        street: this.form.street,
        city: this.form.city,
        houseNumber: this.form.houseNumber,
        postcode: this.form.postcode,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        contactID: this.subUser.ContactID,
        userName: this.subUser.UserName,
        tel: this.form.tel,
        MobilePhone: this.form.MobilePhone,
        CUST_ReceiveNewsletter: this.form.CUST_ReceiveNewsletter === 'accepted'
      })

      if (result !== null)
        this.notify({ message: 'Gegevens succesvol aangepast' })
      this.processing = false
    }
  }
}
</script>
